.w {
  width: 1200px;
  margin: 0 auto;
}
.w2 {
  width: 1055px;
  margin-top: 0 auto;
}
.title {
  margin-top: 63px;
  height: 493px;
  position: relative;
}
.title .titleimg {
  position: absolute;
  right: 0;
  top: 0;
}

.title_top {
  position: absolute;
  top: 100px;
  left: 160px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 32px;
  z-index: 99;
}

.title_docs {
  position: absolute;
  top: 150px;
  left: 160px;
  font-weight: bold;
  font-size: 49px;
  color: #fff;
  z-index: 99;
  line-height: 65px;
}

.title_bottom {
  position: absolute;
  top: 230px;
  left: 160px;
  color: #d0d5ed;
  font-size: 18px;
  line-height: 24px;
  z-index: 99;
}
.card {
  width: 1055px;
  /* background-color: #fff; */
  margin: 0 auto;
  height: 230px;
  position: relative;
  /* margin-top: -100px; */
}
.card .cardBox {
  position: absolute;
  top: -50px;
  left: 0;
  height: 180px;
  width: 1055px;
  /* 
  background-color: #fff; */

  display: flex;
}
.cardBox_left {
  width: 675px;
  height: 180px;
  color: #fff;
  padding: 30px 40px;
  background: url(./bg1-1@2x.png) no-repeat;
  background-size: 675px 180px;
  box-sizing: border-box;
  position: relative;
}

.cardBox_title {
  font-size: 20px;
  font-weight: bold;
}
.cardBox_docs {
  color: #9ec9fc;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
}

.cardBox_btn {
  margin-top: 30px;
  width: 114px;
  height: 32px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cardQrImg {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.cardQrImg >img {
  width: 100px;
  height: 100px;
}
.cardQrImg .cardQrImg_title {
  color: #fff;
  margin-top: 10px;
  font-size: 13px;
}

.cardBox_right {
  width: 380px;
  padding: 23px 45px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
}
.cardBox_right_msg {
  display: flex;
  margin-top: 15px;
}
.cardBox_right_msgBox {
  color: #444;
}

.titleColor {
  color: #9ea1af;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
}
.cardBox_right_cli {
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 36px;
  position: absolute;
  top: 50%;
  left: -18px;
  transform: translateY(-50%);
}
.cardBox_right_title {
  font-weight: bold;
}

/* 架构 */
.structure {
  width: 1055px;
  margin: 0 auto;
  margin-top: 50px;
  height: 600px;
  background: #1c2651;
  border-radius: 4px;
  border: 1px solid #2a3c8a;
  background: url(./diagram@2x.png) no-repeat;
  background-size: 1055px 600px;
  background-color: #1f2a5a;
}

.structure_title {
  font-size: 28px;
  line-height: 37px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.structure_line {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #26efcf;
  margin: 0 auto;
  margin-top: 20px;
}

.zhouqi {
  margin-top: 50px;
}
.zhouqiul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.zhouqili {
  width: 380px;
  background: #1f2a5a;
  border-radius: 4px;
  border: 1px solid #2a3c8a;
  height: 250px;
  margin-bottom: 25px;
  padding: 27px 40px 37px 40px;
  box-sizing: border-box;
}
.zhouqili:hover {
  border: 1px solid #1f6ef7;
}
.fangan {
  height: 425px;
  max-width: 1500px;
  width: 1200px;
  margin-top: 50px;
  /* background-color: skyblue; */
}
.fanganul {
  display: flex;
  background: rgba(9, 35, 146, 0.3);
}
.fanganli {
  width: 25%;
  height: 425px;
  padding: 44px 30px;
  background: url(./bg2-1.jpg) no-repeat;
  background-size: 450px 425px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.7s ease-out;
  overflow: hidden;
}
.fanganli1 {
  background: url(./bg2-1.jpg) no-repeat;
}
.fanganli2 {
  background: url(./bg2-2.jpg) no-repeat;
}
.fanganli3 {
  background: url(./bg2-3.jpg) no-repeat;
}
.fanganli4 {
  background: url(./bg2-4.jpg) no-repeat;
}
.fanganLive {
  width: 35%;
}
.fanganli:hover {
  width: 40%;
  transition: all 0.7s ease-in-out;
}

.fanganliMask {
  /* float: left; */
  position: absolute;
  top: 0;
  left: 0;
  width: 420px;
  height: 425px;
  background-color: transparent;
  transition: all 0.5s ease-out;
}

.fanganliBox {
  position: absolute;
  bottom: -90px;
  left: 40px;
  transition: all 0.7s ease-out;
}
.fanganli:hover .fanganliBox {
  bottom: 45px;
  transition: all 0.7s ease-in-out;
}

.fanganli:hover .fanganliBox_line {
  transition: all 1s ease-in-out;
  background-color: #fff;
}
.fanganli:hover .fanganliBox_docs {
  /* display: block; */
  transition: all 1s ease-in-out;
  color: #fff;
}
.fanganli:hover .fanganliMask {
  background-color: rgba(2, 11, 48, 0.4);
  transition: all 0.5s ease-in;
}
.fanganliBox_line {
  /* display: none; */
  margin-top: 10px;
  width: 40px;
  height: 2px;
  background-color: transparent;
  transition: all 1s ease-in;
}
.fanganliBox_docs {
  /* display: none; */
  font-size: 14px;
  color: transparent;
  line-height: 24px;
  margin-top: 10px;
  width: 220px;
  transition: all 1s ease-in;
}

.more {
  font-size: 14px;
  color: #a2a7bd;
  text-align: center;
  line-height: 19px;
  margin-top: 25px;
}
.logoListul {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logoListli {
  width: 276px;
  height: 100px;
  background: #1c2651;
  border-radius: 0px 0px 0px 4px;
  border: 1px solid #2a3c8a;
  margin-bottom: 12px;
}

.btbanner {
  height: 120px;
  /* background-color: skyblue; */
  margin-top: 40px;
  background: url('./bottombanner.png') no-repeat;
  background-size: 100% 120px;
  padding: 30px 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
}
.btbannerleft_tittle {
  color: #fff;
  font-size: 20px;
  line-height: 26px;
}
.btbannerleft_msg {
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: #9ec9fc;
  font-size: 14px;
  line-height: 19px;
}
.btbannerleft_text {
  margin-left: 4px;
  margin-right: 40px;
}

.btbannerright {
  margin-left: auto;
  width: 114px;
  height: 32px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1f6ef7;
  font-size: 14px;
  cursor: pointer;
}

.btbannerQrImg {
  position: absolute;
  right: 500px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.btbannerQrImg >img {
  width: 70px;
  height: 70px;
}
.btbannerQrImg .btbannerQrImg_title {
  color: #fff;
  margin-top: 5px;
  font-size: 12px;
}

.leftnav {
  width: 120px;
  height: 263px;
  background: #0a1232;
  opacity: 0.7;
  border-radius: 4px;
  position: fixed;
  top: 200px;
  left: 30px;
  box-sizing: border-box;
  color: #6e758f;
}

.item {
  font-size: 14px;
  line-height: 52px;
  text-align: center;
  display: flex;
  align-items: center;
}
.nava {
  color: #6e758f;
  margin-left: 15px;
}

.item:hover .nava {
  color: #fff;
  font-weight: bold;
}
.item_span {
  display: none;
  width: 4px;
  height: 16px;
  line-height: 52px;
}
.active {
  color: #fff;
  font-weight: bold;
}
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.rightFixed {
  position: fixed;
  right: 24px;
  bottom: 100px;
  z-index: 999;
}
.rightFixed .wechatQrBox {
  position: relative;
}
.rightFixed .wechatQrBox .wechatIconPop {
  display: none;
  position: absolute;
  left: -195px;
  top: -175px;
  padding: 32px;
  background: #fff;
  border-radius: 8px;
  width: 120px;
  transition: all 0.3s;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.rightFixed .wechatQrBox .wechatIconPop >img {
  width: 120px;
  height: 120px;
}
.rightFixed .wechatQrBox .wechatIconPop .wechatIconPop_title {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}
.rightFixed .wechatQrBox .wechatIconImg {
  width: 56px;
  height: 56px;
}
.rightFixed .wechatQrBox:hover .wechatIconPop {
  display: block;
}
